import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pass-code-wrapper" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerificationCodeV2 = _resolveComponent("VerificationCodeV2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "h2 centered" }, "Please provide Your Survey Passcode", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "h4 main-paragraph pass-code__main-paragraph" }, " Your passcode is the eight code found in your invitation letter. ", -1)),
    _createVNode(_component_VerificationCodeV2, {
      fields: _ctx.fields,
      devider: 4,
      error: _ctx.error,
      onCode: _ctx.onCodeChanged
    }, null, 8, ["fields", "error", "onCode"]),
    _createElementVNode("button", {
      type: "button",
      disabled: !_ctx.validateLength || _ctx.error || _ctx.loading,
      class: "primary-rewards-btn",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCreate && _ctx.onCreate(...args)), ["prevent"]))
    }, " Continue ", 8, _hoisted_2)
  ]))
}