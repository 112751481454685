import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "survey-info-wrapper" }
const _hoisted_2 = { class: "h2 centered" }
const _hoisted_3 = { class: "h4 main-paragraph main-paragraph_survey-info" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InviteBlockV2 = _resolveComponent("InviteBlockV2")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(`surveyInfo.title`)), 1),
      _createVNode(_component_InviteBlockV2, {
        invite: _ctx.invite,
        images: _ctx.images,
        onCompletionShow: false,
        onCompletionMobileOnly: false
      }, null, 8, ["invite", "images"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("welcome.text", { incentive: 195, surveyLength: 45 })), 1),
      _createElementVNode("a", {
        href: "#",
        class: "violet-link",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLinkClick && _ctx.onLinkClick(...args)))
      }, _toDisplayString(_ctx.$t("welcome.screenerLinkText")), 1),
      _createElementVNode("button", {
        class: "primary-rewards-btn",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onStartScreener && _ctx.onStartScreener(...args)), ["prevent"]))
      }, _toDisplayString(_ctx.$t("welcome.startButtonText")), 1)
    ]),
    _createVNode(_component_CustomModal, {
      name: _ctx.eligibleModalName,
      className: "eligible-modal"
    }, {
      header: _withCtx(() => [
        _createElementVNode("img", {
          src: require('@/assets/images/close.svg'),
          alt: "",
          class: "eligible-modal__close-button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hideEligibleModal && _ctx.hideEligibleModal(...args)))
        }, null, 8, _hoisted_4)
      ]),
      default: _withCtx(() => [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "eligible-modal__content" }, " info ", -1))
      ]),
      _: 1
    }, 8, ["name"])
  ], 64))
}