import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "unsubscribe-reasons" }
const _hoisted_2 = { class: "unsubscribe-reasons__img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "unsubscribe-reasons__title" }
const _hoisted_5 = { class: "h2" }
const _hoisted_6 = { class: "unsubscribe-reasons__desc" }
const _hoisted_7 = { class: "h4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnsubscribeForm = _resolveComponent("UnsubscribeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/images/rewards/unsubscribe-icon.svg'),
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("unsubscribe.reasons.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("unsubscribe.reasons.message")), 1)
    ]),
    _createVNode(_component_UnsubscribeForm)
  ]))
}