import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "address-search__footer" }
const _hoisted_2 = {
  key: 0,
  class: "address-search__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.name ?? _ctx.id,
    rules: _ctx.rules,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event))
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["address-search", [{ 'is-error': errors[0] }]])
      }, [
        _createVNode(_component_InlineSvg, {
          src: require(`@/assets/images/rewards/address.svg`),
          class: "address-search__icon"
        }, null, 8, ["src"]),
        _createVNode(_component_Multiselect, {
          id: "address-search",
          "model-value": field.value,
          onSearchChange: _ctx.onSearch,
          options: _ctx.options,
          trackBy: _ctx.trackBy,
          label: _ctx.label,
          searchable: true,
          internalSearch: false,
          "clear-on-select": false,
          placeholder: "",
          onSelect: _ctx.updateSelected,
          class: _normalizeClass([
          {
            'multiselect--hasData': _ctx.value
          }
        ])
        }, {
          afterList: _withCtx(() => [
            _createElementVNode("li", _hoisted_1, [
              _createElementVNode("button", {
                type: "button",
                class: "violet-link",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFooterClick && _ctx.onFooterClick(...args)), ["prevent"]))
              }, " I don’t see my address ")
            ])
          ]),
          noResult: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" No elements found")
          ])),
          _: 2
        }, 1032, ["model-value", "onSearchChange", "options", "trackBy", "label", "onSelect", "class"]),
        (errors[0])
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(errors[0]), 1))
          : _createCommentVNode("", true),
        (field.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClear && _ctx.onClear(...args))),
              class: "address-search__close"
            }, [
              _createVNode(_component_InlineSvg, {
                src: require(`@/assets/images/rewards/close.svg`)
              }, null, 8, ["src"])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["name", "rules", "modelValue"]))
}