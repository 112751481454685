import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "eligible-modal__content" }
const _hoisted_2 = { class: "terms-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrivacyPolicyContent = _resolveComponent("PrivacyPolicyContent")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createBlock(_component_CustomModal, {
    name: _ctx.modalName,
    className: "eligible-modal"
  }, {
    titleText: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode("Privacy Policy")
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PrivacyPolicyContent, { isModal: true })
        ])
      ])
    ]),
    _: 1
  }, 8, ["name"]))
}