import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "survey-disclaimer-wrapper"
}
const _hoisted_2 = { class: "h2" }
const _hoisted_3 = { class: "survey-disclaimer-wrapper__content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "main-paragraph survey-disclaimer-wrapper__item-text" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "main-paragraph survey-disclaimer-wrapper__item-text" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "main-paragraph survey-disclaimer-wrapper__item-text" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "survey-disclaimer-wrapper__button" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_PopperTooltip = _resolveComponent("PopperTooltip")!
  const _component_SurveyTermsModal = _resolveComponent("SurveyTermsModal")!
  const _component_PrivacyPolicyModal = _resolveComponent("PrivacyPolicyModal")!
  const _component_ParticipantsModal = _resolveComponent("ParticipantsModal")!

  return (_ctx.invite !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(`surveyDisclaimer.title`)), 1),
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "survey-disclaimer-wrapper__item"
            }, [
              (index === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_CustomCheckbox, {
                      id: `item_${index}`,
                      checked: _ctx.selectedItems.includes(index),
                      value: index,
                      onOnChange: ($event: any) => (_ctx.onCheckboxChange(index))
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, [
                          _cache[4] || (_cache[4] = _createTextVNode(" I’ll need to qualify for this survey before being eligible to complete it. ")),
                          _createElementVNode("button", {
                            type: "button",
                            class: "violet-link violet-link_underline font-regular p-0",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onModalOpenClick(_ctx.whatIsScreenerModalName)))
                          }, " Why? ")
                        ])
                      ]),
                      _: 2
                    }, 1032, ["id", "checked", "value", "onOnChange"])
                  ]))
                : (index === 4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_CustomCheckbox, {
                        id: `item_${index}`,
                        checked: _ctx.selectedItems.includes(index),
                        value: index,
                        onOnChange: ($event: any) => (_ctx.onCheckboxChange(index))
                      }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_7, [
                            _cache[5] || (_cache[5] = _createTextVNode(" I have read and accept the ")),
                            _createElementVNode("button", {
                              type: "button",
                              class: "violet-link violet-link_underline font-regular p-0",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onModalOpenClick(_ctx.surveyTermsModalName)))
                            }, " survey terms "),
                            _cache[6] || (_cache[6] = _createTextVNode(" and ")),
                            _createElementVNode("button", {
                              type: "button",
                              class: "violet-link violet-link_underline font-regular p-0",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onModalOpenClick(_ctx.privacyPolicyModalName)))
                            }, " privacy policy ")
                          ])
                        ]),
                        _: 2
                      }, 1032, ["id", "checked", "value", "onOnChange"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_CustomCheckbox, {
                        id: `item_${index}`,
                        checked: _ctx.selectedItems.includes(index),
                        value: index,
                        onOnChange: ($event: any) => (_ctx.onCheckboxChange(index))
                      }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(label), 1)
                        ]),
                        _: 2
                      }, 1032, ["id", "checked", "value", "onOnChange"])
                    ]))
            ]))
          }), 128))
        ]),
        (!_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("br", _hoisted_10))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_PopperTooltip, {
            class: "lightTheme",
            arrow: "",
            hover: "",
            disabled: _ctx.isAllChecked,
            placement: "top"
          }, {
            content: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("p", { class: "" }, " You must agree to all of the terms above by placing a checkmark next to each one. ", -1)
            ])),
            default: _withCtx(() => [
              _createElementVNode("button", {
                type: "button",
                class: "primary-rewards-btn",
                disabled: !_ctx.isAllChecked,
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onStart && _ctx.onStart(...args)))
              }, _toDisplayString(_ctx.$t("continue")), 9, _hoisted_12)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _createVNode(_component_SurveyTermsModal, { modalName: _ctx.surveyTermsModalName }, null, 8, ["modalName"]),
        _createVNode(_component_PrivacyPolicyModal, { modalName: _ctx.privacyPolicyModalName }, null, 8, ["modalName"]),
        _createVNode(_component_ParticipantsModal, { modalName: _ctx.whatIsScreenerModalName }, null, 8, ["modalName"])
      ]))
    : _createCommentVNode("", true)
}