import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "confirm-name" }
const _hoisted_2 = { class: "h1 confirm-name__main" }
const _hoisted_3 = { class: "h2 confirm-name__meta" }
const _hoisted_4 = { class: "h4 confirm-name__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.show)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "h2 confirm-name__title" }, "Is this your correct name?", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("p", { class: "h4 confirm-name__desc" }, " This will be the name your payment check will be made out to. ", -1)),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.client.fullName), 1),
          _createElementVNode("button", {
            class: "primary-rewards-btn confirm-name__button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNext && _ctx.onNext(...args)), ["prevent"]))
          }, " Yup! That’s me "),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              type: "button",
              class: "violet-link",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onToggleView && _ctx.onToggleView(...args)), ["prevent"]))
            }, " No, that’s not correct ")
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("h2", _hoisted_3, " This survey is specifically for " + _toDisplayString(_ctx.client.fullName), 1),
          _createElementVNode("p", _hoisted_4, [
            _cache[5] || (_cache[5] = _createTextVNode(" If that is not you, you are not eligble. ")),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _cache[7] || (_cache[7] = _createTextVNode(" If that is you, but your name is spelled incorrect, please ")),
            _cache[8] || (_cache[8] = _createElementVNode("br", { class: "mobileOn" }, null, -1)),
            _createElementVNode("a", {
              href: "#",
              class: "violet-link",
              onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onOpenChat && _ctx.onOpenChat(...args)), ["prevent"]))
            }, "contact customer service")
          ])
        ], 64))
  ]))
}