import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "redirected" }
const _hoisted_3 = { class: "redirected__img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "redirected__title" }
const _hoisted_6 = { class: "h2" }
const _hoisted_7 = { class: "redirected__desc" }
const _hoisted_8 = { class: "h4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: require('@/assets/images/rewards/redirected-icon.svg'),
          alt: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("status.redirected.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("status.redirected.message")), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "primary-rewards-btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAcceptInvite && _ctx.onAcceptInvite(...args)))
      }, _toDisplayString(_ctx.$t("continue")), 1)
    ])
  ]))
}