import { AxiosError, AxiosResponse } from "axios";

import router from "@/router";

export type ServerError = { errorMessage: string };
export default class HttpError {
  error: AxiosError;
  status?: number;

  constructor(e: AxiosError) {
    this.error = e;
    this.status = e.response?.status ?? 0;
    this.init()
  }

  init() {
    if (this.error.response) {
      // The client was given an error response (5xx, 4xx)
      const response = this.error.response as AxiosResponse
      const message = response.data && response.data.message
          ? response.data.message
          : response.data && response.data.data && response.data.data.exception
          ? response.data.data.exception
          : ""

      console.error(`${this.status} ${message}`);
    } else if (this.error.request) {
      // The client never received a response, and the request was never left
      console.log(this.error.request);
    } else {
      // Anything else
    }
  }
}
