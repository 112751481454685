import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "all-finished" }
const _hoisted_3 = { class: "all-finished__img" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Join = _resolveComponent("Join")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: require('@/assets/images/rewards/complete-icon.svg'),
          alt: ""
        }, null, 8, _hoisted_4)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "all-finished__title h1 violet-color" }, "All finished!!", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "all-finished__desc h4" }, " Thank you for completing the survey! ", -1))
    ]),
    (!_ctx.loading && _ctx.isShowJoinBlock)
      ? (_openBlock(), _createBlock(_component_Join, {
          key: 0,
          npi: _ctx.contact?.npi
        }, null, 8, ["npi"]))
      : _createCommentVNode("", true)
  ]))
}