import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/rewards/paused-icon.svg'


const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Join = _resolveComponent("Join")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"survey-paused\" data-v-7a34be77><div class=\"survey-paused__img\" data-v-7a34be77><img src=\"" + _imports_0 + "\" alt=\"\" data-v-7a34be77></div><div class=\"survey-paused__title\" data-v-7a34be77><div class=\"h2\" data-v-7a34be77>Unfortunately, this survey is under maintenance.</div></div><div class=\"h4\" data-v-7a34be77> Please try again later and we should have it all ready to go. </div></div>", 1)),
    (!_ctx.loading && _ctx.isShowJoinBlock)
      ? (_openBlock(), _createBlock(_component_Join, {
          key: 0,
          title: "Would you like to be notified when the survey is back up?",
          button: "Yes! Please let me know"
        }))
      : _createCommentVNode("", true)
  ]))
}