import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/rewards/clutter.svg'


const _hoisted_1 = { class: "error centered" }
const _hoisted_2 = { class: "sub-heading-3 error__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("img", {
      src: _imports_0,
      class: "error__pic",
      width: "735"
    }, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "h1 main-title error__title" }, "Oops! Something went wrong", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createTextVNode(" The page you’re trying to view doesn’t exist anymore. ")),
      _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("button", {
        type: "button",
        class: "violet-link violet-link_underline error__button",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toHomePage && _ctx.toHomePage(...args)), ["prevent"]))
      }, " Go back to homepage ")
    ])
  ]))
}