import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(["footer-light", [`is-${_ctx.angle}`, `is-${_ctx.size}`]])
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("span", { class: "footer-light__border footer-light__border_first" }, null, -1),
    _createElementVNode("span", { class: "footer-light__border footer-light__border_middle" }, null, -1),
    _createElementVNode("span", { class: "footer-light__border footer-light__border_last" }, null, -1)
  ]), 2))
}