import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "confirm-email" }
const _hoisted_2 = { class: "confirm-email__field" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, { as: "div" }, {
    default: _withCtx(({ meta }) => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "h2 confirm-email__title" }, " We need your email address to get started ", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "h4 confirm-email__desc" }, " We will use this contact information for important messages regarding your survey response. ", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Input, {
            id: "email",
            name: "Email",
            value: "",
            label: "Email Address",
            rules: { required: true, email: true },
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            className: ""
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("button", {
          class: "primary-rewards-btn",
          disabled: !meta.valid,
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNext && _ctx.onNext(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.$t("continue")), 9, _hoisted_3)
      ])
    ]),
    _: 1
  }))
}