import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "terminated" }
const _hoisted_3 = { class: "terminated__img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "terminated__title" }
const _hoisted_6 = { class: "h2" }
const _hoisted_7 = { class: "terminated__desc" }
const _hoisted_8 = { class: "h4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Join = _resolveComponent("Join")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: require('@/assets/images/rewards/terminated-icon.svg'),
          alt: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("status.terminatedV2.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("status.terminatedV2.message")), 1)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "violet-link",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLinkClick && _ctx.onLinkClick(...args)))
      }, _toDisplayString(_ctx.$t("status.terminatedV2.hint")), 1)
    ]),
    (_ctx.invite?.showJoinToPortal)
      ? (_openBlock(), _createBlock(_component_Join, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_CustomModal, {
      name: _ctx.eligibleModalName,
      className: "eligible-modal"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("div", { class: "eligible-modal__content" }, [
          _createElementVNode("h1", null, "Why aren’t you eligible?"),
          _createElementVNode("p", null, " For the final survey results to be accurate and meaningful, the participants have to be representative of the population we're trying to understand. Oftentimes, that means putting in specific requirements and restrictions on different characteristics such as, but not limited to, medical specialization, geographic area, treatment of conditions, scripting of medication, etc. "),
          _createElementVNode("p", null, [
            _createElementVNode("b", null, [
              _createTextVNode(" One thing is for sure, it's "),
              _createElementVNode("span", { style: {"text-decoration":"underline"} }, "not"),
              _createTextVNode(" a reflection of your professional qualifications. In other words, it's us, not you. ")
            ])
          ]),
          _createElementVNode("p", null, " While we can't guarantee eligibility, we'll always try to match you to surveys that are a good fit. But even with that, there are some things we don't know until we ask - for example: how many patients do you see for a specific condition, or prescribe a specific medication to? "),
          _createElementVNode("p", null, " Finally, survey requirements do often change, and previously filled spots open up. Should that happen, we'll reach out to you automatically - nothing for you to do on your end. "),
          _createElementVNode("br")
        ], -1)
      ])),
      _: 1
    }, 8, ["name"])
  ]))
}