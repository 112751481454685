import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "custom-modal__header" }
const _hoisted_2 = { class: "h2 custom-modal__header_title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "custom-modal__body" }
const _hoisted_5 = { class: "custom-modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, {
    modelValue: _ctx.isShown,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isShown) = $event)),
    name: _ctx.name,
    classes: "custom-modal",
    "content-class": `custom-modal__content ${_ctx.className}`
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header", {}, () => [
          _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "titleText", {}, () => [
              _cache[2] || (_cache[2] = _createTextVNode(" "))
            ])
          ]),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShown = false))
          }, [
            _createElementVNode("img", {
              src: require('@/assets/images/close-gray.svg'),
              alt: "",
              class: "custom-modal__close-button"
            }, null, 8, _hoisted_3)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "footer")
      ])
    ]),
    _: 3
  }, 8, ["modelValue", "name", "content-class"]))
}