import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createBlock(_component_CustomModal, {
    name: _ctx.modalName,
    className: "eligible-modal"
  }, {
    titleText: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" Participants must qualify ")
    ])),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "eligible-modal__content" }, [
        _createElementVNode("p", null, " For the final survey results to be accurate and meaningful, participants have to be representative of the population we’re trying to understand. This usually means having specific requirements and restrictions on different characteristics such as, but not limited to, medical specialization, geographic area, treatment of conditions, scripting of medication, etc. "),
        _createElementVNode("p", null, [
          _createElementVNode("b", null, "Qualifying for a survey is not a reflection of your professional qualifications.")
        ]),
        _createElementVNode("p", null, " While we can’t guarantee eligibility, we’ll always try to match you to surveys that are a good fit. But even with that, there are some things we don’t know until we ask - for example: how many patients do you see for a specific condition, or prescribe a specific medication to? "),
        _createElementVNode("br")
      ], -1))
    ]),
    _: 1
  }, 8, ["name"]))
}