import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "welcome-wrapper"
}
const _hoisted_2 = { class: "h2" }
const _hoisted_3 = {
  key: 0,
  class: "centered"
}
const _hoisted_4 = { class: "main-paragraph main-paragraph_welcome h4" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InviteBlockV2 = _resolveComponent("InviteBlockV2")!
  const _component_ParticipantsModal = _resolveComponent("ParticipantsModal")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.invite !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.invite.topic) + " Survey", 1),
          _createVNode(_component_InviteBlockV2, {
            invite: _ctx.invite,
            images: _ctx.inviteBlockImagesConfig,
            onCompletionShow: false,
            onCompletionMobileOnly: false
          }, null, 8, ["invite", "images"]),
          (_ctx.showScreener)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, [
                  _createElementVNode("span", null, [
                    _cache[3] || (_cache[3] = _createTextVNode(" This survey contains screening questions. Participants ")),
                    _createElementVNode("a", {
                      href: "#",
                      class: "violet-link",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onModalOpenClick('whatIsScreenerModal')), ["prevent"]))
                    }, _toDisplayString(_ctx.$t("welcome.screenerLinkText")), 1),
                    _cache[4] || (_cache[4] = _createTextVNode(" and complete the survey to earn the reward. "))
                  ]),
                  (_ctx.hasAdditionalInfo)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _cache[5] || (_cache[5] = _createTextVNode(" There is additional information about this survey, ")),
                        _createElementVNode("a", {
                          href: "#",
                          class: "violet-link",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onModalOpenClick('additionalInfoModal')), ["prevent"]))
                        }, _toDisplayString(_ctx.$t("welcome.additionalInfoLinkText")), 1),
                        _cache[6] || (_cache[6] = _createTextVNode(" . "))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "primary-rewards-btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onStart && _ctx.onStart(...args)))
          }, _toDisplayString(_ctx.$t("welcome.button")), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ParticipantsModal, { modalName: "whatIsScreenerModal" }),
    _createVNode(_component_CustomModal, {
      name: "additionalInfoModal",
      className: "eligible-modal"
    }, {
      titleText: _withCtx(() => _cache[7] || (_cache[7] = [
        _createTextVNode(" Additional Info ")
      ])),
      default: _withCtx(() => [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "eligible-modal__content" }, [
          _createElementVNode("h1", null, "Info would go here")
        ], -1))
      ]),
      _: 1
    })
  ]))
}