import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/images/add.svg'


const _hoisted_1 = { class: "text-color" }
const _hoisted_2 = { class: "container container_full" }
const _hoisted_3 = { class: "container__header" }
const _hoisted_4 = { class: "space-between" }
const _hoisted_5 = { class: "sub-title" }
const _hoisted_6 = { style: {"height":"0px"} }
const _hoisted_7 = {
  key: 0,
  class: "drop-active"
}
const _hoisted_8 = { class: "space-between" }
const _hoisted_9 = { class: "meta" }
const _hoisted_10 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_TablePlaceholder = _resolveComponent("TablePlaceholder")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Title, { size: "full" }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.projectName), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("uploadFiles")), 1),
          _createVNode(_component_FileUpload, {
            disabled: _ctx.loadingUploadFile,
            multiple: true,
            modelValue: _ctx.files,
            class: "secondary-btn",
            "onUpdate:modelValue": _ctx.onUploadFiles
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.loadingUploadFile ? "loading" : "addFile"}`)), 1)
            ]),
            _: 1
          }, 8, ["disabled", "modelValue", "onUpdate:modelValue"])
        ])
      ]),
      _createVNode(_component_Table, {
        columns: _ctx.columns,
        rows: _ctx.rows,
        config: _ctx.tableConfig,
        class: "custom-table_height",
        onOnToSortClick: _ctx.onSortAction
      }, {
        "table-placeholder": _withCtx(() => [
          (!(_ctx.$refs.upload && _ctx.$refs.upload.dropActive))
            ? (_openBlock(), _createBlock(_component_TablePlaceholder, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        "table-bottom": _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_FileUpload, {
              ref: "upload",
              multiple: true,
              modelValue: _ctx.files,
              drop: true,
              "onUpdate:modelValue": _ctx.onUploadFiles
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            (_ctx.$refs.upload && _ctx.$refs.upload.dropActive)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[0] || (_cache[0] = _createElementVNode("img", {
                    src: _imports_0,
                    class: "table-placeholder__img",
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("dragAndDrop")), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        row_fileName: _withCtx(({ option: { row: file } }) => [
          _createTextVNode(_toDisplayString(`${file.fileName}.${file.fileExtension}`), 1)
        ]),
        row_fileSize: _withCtx(({ option: { row: file } }) => [
          _createTextVNode(_toDisplayString(_ctx.formatBytesSize(Number(file.fileSize))), 1)
        ]),
        row_fileCreatedAt: _withCtx(({ option: { row: file } }) => [
          _createTextVNode(_toDisplayString(_ctx.formatDate(file.fileCreatedAt)), 1)
        ]),
        buttons_action: _withCtx(({ option: { row: file } }) => [
          _createVNode(_component_ButtonIcon, {
            icon: _ctx.fileRemove.includes(file.fileId) ? 'update' : 'remove',
            animation: _ctx.fileRemove.includes(file.fileId),
            onOnClick: ($event: any) => (_ctx.$emit('onFileRemove', file))
          }, null, 8, ["icon", "animation", "onOnClick"])
        ]),
        _: 1
      }, 8, ["columns", "rows", "config", "onOnToSortClick"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("secureFile")), 1),
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("dragAndDrop")), 1)
    ])
  ], 64))
}