import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "verification" }
const _hoisted_2 = { class: "verification__block" }
const _hoisted_3 = {
  key: 0,
  class: "verification__meta"
}
const _hoisted_4 = {
  key: 1,
  class: "verification__meta verification__meta_md"
}
const _hoisted_5 = { class: "verification__countDown" }
const _hoisted_6 = { class: "verification__blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerificationInputCode = _resolveComponent("VerificationInputCode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "verification__title" }, "Two Factor Authentication", -1)),
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "verification__subtitle" }, " Please enter a Verification Code sent to your email ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "verification__desc" }, "Verification Code", -1)),
      _createElementVNode("div", {
        class: _normalizeClass(["verification__form", { 'is-error': _ctx.applyCodeError }])
      }, [
        _createVNode(_component_VerificationInputCode, {
          fields: _ctx.codeLength,
          onCode: _ctx.onCodeChanged,
          onOnEnterEvent: _ctx.onAction
        }, null, 8, ["fields", "onCode", "onOnEnterEvent"]),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "verification__error" }, "Invalid Code Entered", -1))
      ], 2),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass([{ 'is-disabled': !_ctx.code.length || _ctx.loading }, "secondary-btn verification__button"]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onAction && _ctx.onAction(...args)), ["prevent"]))
      }, " Continue ", 2),
      (this.countDown === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createTextVNode(" Didn't receive the email? ")),
            _createElementVNode("button", {
              type: "button",
              class: "blue-link verification__link",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onResent && _ctx.onResent(...args)), ["prevent"]))
            }, " Resend it ")
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createTextVNode(" Please wait ")),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.countDown), 1),
            _cache[5] || (_cache[5] = _createTextVNode(" seconds ")),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _cache[7] || (_cache[7] = _createTextVNode("to request a new Code "))
          ]))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["verification__row", { 'is-hide': !_ctx.getEmail.length || _ctx.getEmail === 'undefined' }])
    }, [
      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "verification__meta" }, "For verifying email", -1)),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getEmail), 1)
    ], 2),
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "verification__subtitle verification__footer" }, " This code is valid for 30 minutes. Please continue the next step in time. ", -1))
  ]))
}