import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "onUpdate:modelValue"]
const _hoisted_2 = {
  key: 0,
  class: "pass-code__devider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pass-code", { 'is-error': _ctx.error }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `input_${index}`
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          pattern: "\\d\\w*",
          id: 'input_' + index,
          maxlength: "1",
          class: "pass-code__input",
          "onUpdate:modelValue": ($event: any) => ((_ctx.code[index]) = $event),
          placeholder: "0",
          autocomplete: "off",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleDelete && _ctx.handleDelete(...args)), ["delete"])),
          onPaste: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onPaste && _ctx.onPaste(...args)))
        }, null, 40, _hoisted_1), [
          [_vModelText, _ctx.code[index]]
        ]),
        (_ctx.devider && index === _ctx.devider - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "h4 pass-code__error-text" }, "Invalid Passcode", -1))
  ], 2))
}