import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../assets/images/rewards/back.svg'


const _hoisted_1 = { class: "header__back-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", [
      `is-${_ctx.size}`,
      {
        'is-mobile-logo': _ctx.mobileLogo,
      },
      {
        'header_terms': _ctx.terms,
      }
    ]])
  }, [
    (_ctx.back)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "header__back",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBack && _ctx.onBack(...args)))
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_0,
            class: "header__back-image",
            alt: "back"
          }, null, -1)),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("back")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("a", {
      href: "#",
      class: "header__logo",
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onLogoClick && _ctx.onLogoClick(...args)), ["prevent"]))
    })
  ], 2))
}