/* eslint-disable no-unused-vars */
export enum EndpointTypes {
  CHECK_UNSUBSCRIBE = "CHECK_UNSUBSCRIBE",
  CHECK_INVITE = "CHECK_INVITE",
  CHECK_BY_REF_ID = "CHECK_BY_REF_ID",
  CHECK_DECLINE = "CHECK_DECLINE",
  SET_UNSUBSCRIBE = "SET_UNSUBSCRIBE",
  UNSUBSCRIBE_DAILY_DIGEST = "UNSUBSCRIBE_DAILY_DIGEST",
  SET_DECLINE = "SET_DECLINE",
  SURVEY_DISCLAIMER_EVENT = "SURVEY_DISCLAIMER_EVENT",
  SURVEY_START_EVENT = "SURVEY_START_EVENT",
  ON_PAUSE_EVENT = "ON_PAUSE_EVENT",
  ON_OPENED_EVENT = "ON_OPENED_EVENT",

  GET_PRACTICE_LOCATION_ADDRESS = "GET_PRACTICE_LOCATION_ADDRESS",
  CONTACT_ADDRESS = "CONTACT_ADDRESS",
  EZ_CONTACT_ADDRESS = "EZ_CONTACT_ADDRESS",
  CONFIRM_ADDRESS = "CONFIRM_ADDRESS",

  GET_GEO_LOCATION = "GET_GEO_LOCATION",

  VENDOR_FILES = "VENDOR_FILES",
  VENDOR_FILE_UPLOAD = "VENDOR_FILE_UPLOAD",
  VENDOR_FILE_DELETE = "VENDOR_FILE_DELETE",
  VENDOR_FILE_DOWNLOAD = "VENDOR_FILE_DOWNLOAD",
  VENDOR_FILE_DOWNLOAD_ALL = "VENDOR_FILE_DOWNLOAD_ALL",
  VENDOR_SEND_CODE = "VENDOR_SEND_CODE",
  VENDOR_GENERATE_CODE = "VENDOR_GENERATE_CODE",
  VENDOR_GENERATE_SEND_CODE = "VENDOR_GENERATE_SEND_CODE",
  VENDOR_APPLY_CODE = "VENDOR_APPLY_CODE",

  CLIENT_FILES = "CLIENT_FILES",
  CLIENT_FILE_UPLOAD = "CLIENT_FILE_UPLOAD",
  CLIENT_FILE_DELETE = "CLIENT_FILE_DELETE",
  CLIENT_FILE_DOWNLOAD = "CLIENT_FILE_DOWNLOAD",
  CLIENT_FILE_DOWNLOAD_ALL = "CLIENT_FILE_DOWNLOAD_ALL",
  CLIENT_SEND_CODE = "CLIENT_SEND_CODE",
  CLIENT_GENERATE_CODE = "CLIENT_GENERATE_CODE",
  CLIENT_GENERATE_SEND_CODE = "CLIENT_GENERATE_SEND_CODE",
  CLIENT_APPLY_CODE = "CLIENT_APPLY_CODE",
  VENDOR = "VENDOR",

  GET_CONTACT = "GET_CONTACT",
  CHANGE_CONTACT = "CHANGE_CONTACT",
}
