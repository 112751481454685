import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "decline" }
const _hoisted_2 = { class: "decline__message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonsWrap = _resolveComponent("ButtonsWrap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("decline.message", { name: _ctx.invite?.topic })), 1),
    _createVNode(_component_ButtonsWrap, {
      confirmTitle: _ctx.$t('decline.confirmButton'),
      cancelTitle: _ctx.$t('decline.nevermindButton'),
      onConfirm: _ctx.onConfirm,
      onCancel: _ctx.onCancel
    }, null, 8, ["confirmTitle", "cancelTitle", "onConfirm", "onCancel"])
  ]))
}