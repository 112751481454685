import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "invitation-template__in" }
const _hoisted_2 = { class: "invitation-template__in" }
const _hoisted_3 = { class: "invitation-template-form__content invitation-template-form__content_in" }
const _hoisted_4 = { class: "invitation-template-form__item invitation-template-form__item_slit" }
const _hoisted_5 = { class: "invitation-template-form__input" }
const _hoisted_6 = { class: "invitation-template-form__input" }
const _hoisted_7 = { class: "invitation-template-form__item" }
const _hoisted_8 = { class: "invitation-template-form__input" }
const _hoisted_9 = { class: "invitation-template-form__item" }
const _hoisted_10 = { class: "invitation-template-form__input" }
const _hoisted_11 = { class: "invitation-template-form__item" }
const _hoisted_12 = { class: "invitation-template-form__input" }
const _hoisted_13 = { class: "invitation-template-form__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_StatusCompletedLink = _resolveComponent("StatusCompletedLink")!
  const _component_StatusCompletedSuccessForm = _resolveComponent("StatusCompletedSuccessForm")!

  return (!_ctx.success)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_Title, null, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(`status.completed.title`)), 1)
          ]),
          desc: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(`status.completed.message`)), 1)
          ]),
          _: 1
        }),
        _createElementVNode("form", {
          class: "invitation-template-form",
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onAction && _ctx.onAction(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.firstName) = $event)),
                  required: "",
                  id: "firstName",
                  type: "text"
                }, null, 512), [
                  [_vModelText, _ctx.user.firstName]
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("label", {
                  for: "firstName",
                  title: "First Name"
                }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.lastName) = $event)),
                  required: "",
                  id: "lastName",
                  type: "text"
                }, null, 512), [
                  [_vModelText, _ctx.user.lastName]
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("label", {
                  for: "lastName",
                  title: "Last Name"
                }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.address) = $event)),
                  required: "",
                  id: "address",
                  type: "text"
                }, null, 512), [
                  [_vModelText, _ctx.user.address]
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("label", {
                  for: "address",
                  title: "Address"
                }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.email) = $event)),
                  required: "",
                  id: "email",
                  type: "text"
                }, null, 512), [
                  [_vModelText, _ctx.user.email]
                ]),
                _cache[10] || (_cache[10] = _createElementVNode("label", {
                  for: "email",
                  title: "Email"
                }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.phoneNumber) = $event)),
                  required: "",
                  id: "phoneNumber",
                  type: "text"
                }, null, 512), [
                  [_vModelText, _ctx.user.phoneNumber]
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("label", {
                  for: "phoneNumber",
                  title: "Phone Number"
                }, null, -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("button", {
              type: "button",
              class: "primary-btn",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onAction && _ctx.onAction(...args)))
            }, _toDisplayString(_ctx.$t("status.completed.buttonAction")), 1)
          ])
        ], 32),
        _createVNode(_component_StatusCompletedLink)
      ], 64))
    : (_openBlock(), _createBlock(_component_StatusCompletedSuccessForm, {
        key: 1,
        user: _ctx.user
      }, null, 8, ["user"]))
}