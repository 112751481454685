import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/rewards/expired-icon.svg'


const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Join = _resolveComponent("Join")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"survey-expired\" data-v-dc53eae2><div class=\"survey-expired__img\" data-v-dc53eae2><img src=\"" + _imports_0 + "\" alt=\"\" data-v-dc53eae2></div><div class=\"survey-expired__title\" data-v-dc53eae2><div class=\"h2\" data-v-dc53eae2>This survey has expired.</div></div></div>", 1)),
    (!_ctx.loading && _ctx.isShowJoinBlock)
      ? (_openBlock(), _createBlock(_component_Join, {
          key: 0,
          title: "Want to get notified of surveys quicker?  Join our panel!",
          button: "Click here to sign up"
        }))
      : _createCommentVNode("", true)
  ]))
}