import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/rewards/clutter.svg'


const _hoisted_1 = { class: "error centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      src: _imports_0,
      class: "error__pic",
      width: "735"
    }, null, -1),
    _createElementVNode("div", { class: "h1 main-title error__title" }, "This link has expired", -1)
  ])))
}