import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/rewards/complete-icon.svg'


const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "completed" }
const _hoisted_3 = { class: "completed__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Join = _resolveComponent("Join")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"completed__img\" data-v-60c44c30><img src=\"" + _imports_0 + "\" alt=\"\" data-v-60c44c30></div><div class=\"completed__title\" data-v-60c44c30><div class=\"h2\" data-v-60c44c30><span class=\"violet-color\" data-v-60c44c30>You already completed this survey.</span><br data-v-60c44c30> Thank you so much! </div></div>", 2)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "h4" }, " If you believe this is an error,", -1)),
        _createElementVNode("button", {
          type: "button",
          class: "completed__link violet-link",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onOpenChat && _ctx.onOpenChat(...args)))
        }, " contact customer service ")
      ])
    ]),
    (_ctx.invite?.showJoinToPortal)
      ? (_openBlock(), _createBlock(_component_Join, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}