import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/dollar.svg'


const _hoisted_1 = { class: "contact" }
const _hoisted_2 = { class: "contact__in" }
const _hoisted_3 = { class: "contact__content" }
const _hoisted_4 = { class: "contact__title" }
const _hoisted_5 = { class: "contact__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "contact__label" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }),
        _createTextVNode(" 200 ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.fullName), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.contactInfo), 1)
      ])
    ])
  ]))
}