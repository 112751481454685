import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "unsubscribe-daily" }
const _hoisted_2 = {
  key: 0,
  class: "unsubscribe-daily__login"
}
const _hoisted_3 = { class: "unsubscribe-daily__title" }
const _hoisted_4 = { class: "h2" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "unsubscribe-daily__img" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "unsubscribe-daily__title unsubscribe-daily__title_small" }
const _hoisted_9 = { class: "h2" }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "unsubscribe-daily__title" }
const _hoisted_12 = { class: "h2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonsWrap = _resolveComponent("ButtonsWrap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.unsubscribed && !_ctx.canceled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("unsubscribeDaily.title")), 1)
          ]),
          _createVNode(_component_ButtonsWrap, {
            confirmTitle: _ctx.$t('unsubscribeDaily.confirmButton'),
            cancelTitle: _ctx.$t('unsubscribeDaily.cancelButton'),
            onConfirm: _ctx.onConfirm,
            onCancel: _ctx.onCancel
          }, null, 8, ["confirmTitle", "cancelTitle", "onConfirm", "onCancel"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.unsubscribed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              src: require('@/assets/images/rewards/trash-email.svg'),
              alt: "img"
            }, null, 8, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t("unsubscribeDaily.confirmTitle")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.canceled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t("unsubscribeDaily.cancelTitle")), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}