import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "join" }
const _hoisted_2 = { class: "join__title h3" }
const _hoisted_3 = { class: "join__content" }
const _hoisted_4 = { class: "join__content-left" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "join__content-center" }
const _hoisted_7 = { class: "join__content-right" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: require(`@/assets/images/rewards/arrow-left.svg`),
          alt: "arr"
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "primary-rewards-btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onClick()), ["prevent"]))
        }, _toDisplayString(_ctx.button), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          src: require(`@/assets/images/rewards/arrow-right.svg`),
          alt: "arr"
        }, null, 8, _hoisted_8)
      ])
    ])
  ]))
}