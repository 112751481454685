import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "survey-resume" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "survey-resume__title" }, [
        _createElementVNode("div", { class: "h2" }, "Please click here to “Resume” your Survey.")
      ], -1)),
      _createElementVNode("button", {
        class: "primary-rewards-btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onAcceptInvite()))
      }, _toDisplayString(_ctx.$t("resume")), 1)
    ])
  ]))
}