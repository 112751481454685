
import { Options, mixins } from "vue-class-component"
import Join from "@/components/Join/index.vue"
import Panelist from "@/mixins/Panelist"
import { preventAnyNavigation } from "@/utils/routerHelpers"
import { RouteLocationRaw, NavigationGuardNext } from "vue-router"
@Options({
  components: {
    Join
  },
  beforeRouteLeave(
    to: RouteLocationRaw,
    from: RouteLocationRaw,
    next: NavigationGuardNext
  ) {
    preventAnyNavigation(to, from, next)
  }
})
export default class allFinished extends mixins(Panelist) {}
