import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/rewards/overQuota-icon.svg'


const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Join = _resolveComponent("Join")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"over-quota\" data-v-d8b401ea><div class=\"over-quota__img\" data-v-d8b401ea><img src=\"" + _imports_0 + "\" alt=\"\" data-v-d8b401ea></div><div class=\"over-quota__title\" data-v-d8b401ea><div class=\"h2\" data-v-d8b401ea>Aw, crum! This survey is already full.</div></div><div class=\"h4\" data-v-d8b401ea> We’ll notify you as soon as possible if any openings become available. <span class=\"over-quota__meta\" data-v-d8b401ea>Thank you for your time.</span></div></div>", 1)),
    (_ctx.invite?.showJoinToPortal)
      ? (_openBlock(), _createBlock(_component_Join, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}