import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/images/logo.svg'
import _imports_1 from '../assets/images/award.png'
import _imports_2 from '../assets/images/fb.svg'
import _imports_3 from '../assets/images/in.svg'
import _imports_4 from '../assets/images/tw.svg'


const _hoisted_1 = { class: "invitation-template-email__right" }
const _hoisted_2 = { class: "invitation-template-email__content" }
const _hoisted_3 = {
  key: 0,
  class: "invitation-template-email__footer"
}
const _hoisted_4 = { class: "invitation-template-email__footer-inner" }
const _hoisted_5 = { class: "invitation-template-email__footer-item invitation-template-email__footer-item_phone" }
const _hoisted_6 = { class: "invitation-template-email__desc invitation-template-email__desc_bold" }
const _hoisted_7 = { class: "invitation-template-email__desc" }
const _hoisted_8 = { class: "invitation-template-email__footer-item invitation-template-email__footer-item_address" }
const _hoisted_9 = { class: "invitation-template-email__desc invitation-template-email__desc_bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["invitation-template-email", [
      {
        'invitation-template-email_layout-sm': _ctx.layout === 'sm',
        'invitation-template-email_layout-sm-center': _ctx.layout === 'sm-center',
        'invitation-template-email_layout-left': _ctx.layout === 'left',
      },
      _ctx.staticClass || '',
    ]])
  }, [
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"invitation-template-email__left\"><div class=\"invitation-template-email__left-in\"><a class=\"invitation-template-email__logo\" href=\"https://www.medsurvey.com\" target=\"_blank\" rel=\"noopener noreferrer\"><img src=\"" + _imports_0 + "\" alt=\"Logo\"></a><img class=\"invitation-template-email__award\" src=\"" + _imports_1 + "\" alt=\"award\"></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("a", {
          class: "invitation-template-email__logo invitation-template-email__logo_mobile",
          href: "#"
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Logo"
          })
        ], -1)),
        _renderSlot(_ctx.$slots, "content")
      ]),
      (_ctx.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("phone")), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("workTime")), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("address")), 1)
              ])
            ]),
            _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"invitation-template-email__footer-item invitation-template-email__footer-item_social\"><ul class=\"invitation-template-email__social\"><li class=\"invitation-template-email__social-item\"><a href=\"https://www.facebook.com/medsurvey\" target=\"_blank\"><img src=\"" + _imports_2 + "\" alt=\"\"></a></li><li class=\"invitation-template-email__social-item\"><a href=\"https://www.linkedin.com/company/medsurvey-inc\" target=\"_blank\"><img src=\"" + _imports_3 + "\" alt=\"\"></a></li><li class=\"invitation-template-email__social-item\"><a href=\"https://twitter.com/medsurvey\" target=\"_blank\"><img src=\"" + _imports_4 + "\" alt=\"\"></a></li></ul></div>", 1))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}