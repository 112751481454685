import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "invitation-block" }
const _hoisted_2 = { class: "invitation-block__item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "invitation-block__item-title-wrapper" }
const _hoisted_6 = { class: "invitation-block__item-title h3" }
const _hoisted_7 = { class: "invitation-block__item" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "invitation-block__item-title h3 mobileOn" }
const _hoisted_11 = { class: "invitation-block__item-title h3 mobileOff" }
const _hoisted_12 = { class: "invitation-block__item" }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "invitation-block__item-image mobileOff",
        src: 
          require(`@/assets/images/rewards/${_ctx.images.incentive.regular}.svg`)
        
      }, null, 8, _hoisted_3),
      _createElementVNode("img", {
        class: "invitation-block__item-image mobileOn",
        src: require(`@/assets/images/rewards/${_ctx.images.incentive.mobile}.svg`)
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getIncentiveText), 1),
        (_ctx.onCompletionShow)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["invitation-block__item-subtitle", [{ mobileOn: _ctx.onCompletionMobileOnly }]])
            }, _toDisplayString(_ctx.$t("welcome.onCompletion")), 3))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("img", {
        class: "invitation-block__item-image mobileOff",
        src: require(`@/assets/images/rewards/${_ctx.images.clock.regular}.svg`)
      }, null, 8, _hoisted_8),
      _createElementVNode("img", {
        class: "invitation-block__item-image mobileOn",
        src: require(`@/assets/images/rewards/${_ctx.images.clock.mobile}.svg`)
      }, null, 8, _hoisted_9),
      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.invite.surveyLength) + " " + _toDisplayString(_ctx.$t("mins")), 1),
      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.invite.surveyLength) + " " + _toDisplayString(_ctx.$t("minutes")), 1)
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("img", {
        class: "invitation-block__item-image mobileOff",
        src: require(`@/assets/images/rewards/${_ctx.images.online.regular}.svg`)
      }, null, 8, _hoisted_13),
      _createElementVNode("img", {
        class: "invitation-block__item-image mobileOn",
        src: require(`@/assets/images/rewards/${_ctx.images.online.mobile}.svg`)
      }, null, 8, _hoisted_14),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "invitation-block__item-title h3" }, "Internet", -1))
    ])
  ]))
}